import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/discount-app-components/build/esm/styles.css';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import APIProvider from './APIProvider';
import App from './App';
import { createQueryClient } from './lib/ReactQueryConfig';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `bulk-discounts-frontend@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://e0a169b8b8e041ed813601724adeb32c@o155661.ingest.sentry.io/1219135',
		integrations: defaults => [...defaults, Sentry.browserTracingIntegration()],
		tracesSampleRate: 1,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const queryClient = createQueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<ScrollToTop>
						<App />
					</ScrollToTop>
					<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} location="nw" />
				</Router>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</APIProvider>
	</StrictMode>
);
